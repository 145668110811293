import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { articles } from "../../content/HelpAndSupportPage"
import InnerArticlePage from "../../components/molecules/InnerArticlePage"

const slug = `security-and-fraud`;

const post = articles.find(post => post.slug === slug) // Find the blog post by slug

const SecurityAndFraud = () => (
  <Layout>
    <Seo title="SecurityAndFraud" />

    <InnerArticlePage post={post}  />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default SecurityAndFraud
