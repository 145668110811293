import React from "react"
import {
  articles,
  faqs,
  helpAndSupportPage,
  needHelpSection,
} from "../../content/HelpAndSupportPage"
import NeedHelpSection from "../pages/HelpAndSupport/NeedHelpSection"
import FaqsSection from "../common/FaqsSection"
import ContactSection from "../common/ContactSection"
import { contactSection } from "../../content/Common"
import InnerHeader from "./InnerHeader"
import { Link } from "gatsby"
import Faqs from "./Faqs"
import { postTypesSlug } from "../../content/Global"

const InnerArticlePage = ({ post }) => {
  return (
    <>
      <InnerHeader
        title={helpAndSupportPage.title}
        description={helpAndSupportPage.description}
        googleReviewText={helpAndSupportPage.googleReviewText}
        isSearchBox={true}
        formDetails={helpAndSupportPage.formDetails}
      />

      <div className="cc-section--horizontal-space flex flex-col md:flex-row justify-center gap-[35px] pt-16 md:pt-20 mb-20">
        <div className="flex-[1]">
          {articles.map((item, index) => (
            <div key={`article_link_` + index} className="mb-4">
              <Link
                to={postTypesSlug.supportArticlesSlug + item.slug}
                href={postTypesSlug.supportArticlesSlug + item.slug}
                className={`text-[20px] leading-[1.4em] ${
                  item.slug === post.slug ? "text-secondary font-[700]" : "text-[#081615]"
                }`}
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
        <div className="flex-[3]">
            <h2 className="text-[32px] md:text-[52px] leading-[1.4em] font-[600] mb-10">{post.title}</h2>
            <Faqs faqs={faqs} />
        </div>
      </div>

      <NeedHelpSection data={needHelpSection} />

      <FaqsSection faqs={faqs} />

      <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
    </>
  )
}

export default InnerArticlePage
